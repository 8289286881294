import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ContactForm from "../components/contactForm"

export const query = graphql`
  query($Slug: String) {
    sanityJobs(slug: { current: { eq: $Slug } }) {
      filian
      title
      slug {
        current
      }
      file {
        asset {
          url
        }
      }
    }
  }
`

const subPage = props => {
  return (
    <Layout>
      <SEO
        title={props.data.sanityJobs.title}
        metaDescription={props.data.sanityJobs.title}
        seoTitle={props.data.sanityJobs.title}
      />

      <section className="insight_subpage subpage">
        <iframe
          src={`${props.data.sanityJobs.file.asset.url}`}
          width="100%"
          frameBorder="none"
          allowtransparency="true"
          title="job-iframe"
          style={{ height: "100vh" }}
        ></iframe>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default subPage
